module services {
    export module reporting {
        export class templateService implements interfaces.reporting.ITemplateService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(ownerEntityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Template/GetForDropDown", {
                    ownerEntityId: ownerEntityId
                });
            }

            //for now type id 254 = Letter Of Credit Addendum, 353 = Letter of Credit Amendment Addendum
            getDropdownListByType(ownerEntityId: number, typeId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Template/GetForDropDownByType", {
                    ownerEntityId: ownerEntityId,
                    typeId: typeId
                });
            }
        }
    }
    angular.module("app").service("templateService", services.reporting.templateService);
}